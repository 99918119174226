html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Victor Mono';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-container {
  width: 100%;
  height: 100%;
  text-align: center;
}