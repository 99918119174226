.home-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #848482;

  .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      color: #FADA5E;
      font-size: 48px;
      font-weight: 700;
      margin-bottom: 24px;
      text-transform: uppercase;
    }

    .caption {
      color: #CCCCCC;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 24px;
    }
  }
}